import { start } from 'single-spa';
import registerApplications from './closures/registerApplications';
import { interfaces, behavior } from '@jarvis/shell-commons';
import logger from './interfaces/logger';
import initializeApplicationStatusListener from './closures/initializeApplicationStatusListener';
import setGlobalJarvisWebHTTPConfig from './closures/setGlobalJarvisWebHTTPConfig';
import shellDefaultDependencies from './public/shell-default-dependencies.json';
import { startServiceWorkers } from './services/serviceWorker';
import { setImportMapOnWindow, getImportMap } from './utils/handleImportMap';
import { ManifestType } from './types/manifest';
import { WindowShellType } from './types/shell';
import splunkRum from './services/splunkRum';

export declare type InterfacesInitializerStatePropsType = {
  manifest: ManifestType;
  defaultAssetReferences?: AssetReferencesMap;
  [key: string]: unknown;
};
export declare type AssetReferencesMap = {
  [key: string]: string;
};

(async () => {
  const importmap = await getImportMap();
  setImportMapOnWindow(importmap);

  startServiceWorkers();
  const manifest = window.Shell?.manifest as unknown as ManifestType;

  splunkRum.init(manifest);

  initializeApplicationStatusListener();
  // TODO: this should be deprecated
  setGlobalJarvisWebHTTPConfig({ cache: manifest?.services?.cache });
  if (!manifest?.portal?.removeVeneerCss) {
    import('@veneer/core/dist/css/veneer.css');
  }

  await interfaces._shell.init(
    //@ts-ignore
    {
      manifest,
      defaultAssetReferences: shellDefaultDependencies?.imports,
      events: undefined
    } as InterfacesInitializerStatePropsType
  );
  //@ts-ignore
  window.Shell = { manifest, importmap, ...interfaces } as WindowShellType;

  // FIXME: behavior to be CammelCase since it's a class.
  await behavior?.init?.(interfaces);

  logger.debug('Inject commons on window');

  await registerApplications({ manifest, interfaces });

  start({
    urlRerouteOnly: true
  });
})();
