import { registerApplication } from 'single-spa';
import { services, utils } from '@jarvis/shell-commons';
import * as T from './types';
import applicationStatusManager from '../../services/applicationStatusManager';

let initialized = false;

export default async function registerApplications(
  initializerState: T.registerApplicationsPropsType
): Promise<void> {
  if (initialized) {
    throw new Error('registerApplications was already called');
  }
  initialized = true;

  const { manifest, interfaces } = initializerState;
  const { store, navigation, sessionInterface } = interfaces?.v1 || {};
  const isLoggedIn = !!sessionInterface?.isLoggedIn?.();
  const stack = utils.stackValidator(manifest?.portal?.stack);
  const tenantSelectorAssetReference = '@jarvis/react-shell-tenant-selector';

  const getCurrentRoute = () =>
    utils.getCurrentRoute({
      routes: manifest?.navigation?.routes,
      navigation
    });

  const customProps = {
    mosaicProps: manifest,
    session: services.session,
    stack: stack,
    history: navigation,
    navigateFunction: navigation.push,
    getCurrentRoute,
    applicationStatusManager
  };

  document.title = manifest?.portal?.appName;

  store.setState({
    manifest,
    session: { isLoggedIn }
  });

  registerApplication({
    name: '@jarvis/shell-nav',
    app: () => {
      return System.import('@jarvis/shell-nav') as Promise<any>;
    },
    activeWhen: () => true,
    customProps: {
      ...customProps,
      // TODO: This won't be necessary anymore.
      tenantSelector: tenantSelectorAssetReference
    }
  });
}
