import SplunkOtelWeb, {
  SplunkOtelWebType,
  SessionBasedSampler
} from '@splunk/otel-web';
import { ManifestType } from '../../types/manifest';

class SplunkRum {
  static SessionBasedSampler: any;

  init(manifest: ManifestType) {
    const { enabled, rumAuth, appName, ratio } =
      manifest?.services?.splunk || {};
    const { appName: portalAppName, stack: environment } =
      manifest?.portal || {};

    try {
      if (enabled && rumAuth) {
        const splunkRum = this.getSplunkRum();
        splunkRum.init({
          beaconUrl: 'https://rum-ingest.us1.signalfx.com/v1/rum',
          rumAuth,
          app: appName || portalAppName,
          environment,
          tracer: {
            sampler: new SessionBasedSampler({
              ratio: this.getValidRatio(ratio)
            })
          }
        });
        this.logSplunkSessionIdWhenAvailable();
      }
    } catch (error) {
      console.error(error);
    }
  }

  private getValidRatio(ratio: number): number {
    return typeof ratio === 'number' && ratio >= 0.0 && ratio <= 1.0
    ? ratio
    : 0.5;
  }

  private logSplunkSessionIdWhenAvailable() {
    let loggedToBrowser = false;
    let loggedToJWeb = false;
    const startTime = new Date().getMilliseconds();
    const timeout = 10 * 1000;
    const intervalCheckToLogSplunkId = setInterval(() => {
      try {
        const splunkRum = this.getSplunkRum();
        const splunkRumSessionId = splunkRum?.getSessionId?.();
        const jWeb = (window as any)?.JWeb;

        if (splunkRumSessionId) {
          const message = `SplunkRum sessionId: ${splunkRumSessionId}`;

          loggedToBrowser = this.logToBrowser(message, loggedToBrowser);
          loggedToJWeb = this.logToJWeb(message, jWeb, loggedToJWeb);

          if (
            this.shouldClearInterval(
              loggedToBrowser,
              loggedToJWeb,
              startTime,
              timeout
            )
          ) {
            clearInterval(intervalCheckToLogSplunkId);
          }
        }
      } catch (error) {
        console.error('SplunkRum initialization error: ', error);
      }
    }, 500);
  }

  private getSplunkRum(): SplunkOtelWebType {
    if (!(window as any).SplunkRum) {
      (window as any).SplunkRum = SplunkOtelWeb;
    }
    return (window as any).SplunkRum;
  }

  private logToBrowser(message: string, loggedToBrowser: boolean): boolean {
    if (!loggedToBrowser) {
      console.debug(message);
      return true;
    }
    return loggedToBrowser;
  }
  private logToJWeb(
    message: string,
    jWeb: any,
    loggedToJWeb: boolean
  ): boolean {
    if (jWeb && !loggedToJWeb && jWeb?.isNative) {
      jWeb?.Plugins?.Console?.log?.({
        logLevel: 'LOG',
        message
      });
      return true;
    }
    return loggedToJWeb;
  }

  private shouldClearInterval(
    loggedToBrowser: boolean,
    loggedToJWeb: boolean,
    startTime: number,
    timeout: number
  ): boolean {
    const duration = new Date().getTime() - startTime;
    return (loggedToBrowser && loggedToJWeb) || duration > timeout;
  }
}

const splunkRum = new SplunkRum();

export default splunkRum;
